import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import PageTransitionWrapper from './PageTransitionWrapper';

const ContactUs = () => {
  const [status, setStatus] = useState('');

  const sendEmail = async (e) => {
    e.preventDefault();
    setStatus('Sending...');

    try {
      const templateParams = {
        to_name: 'Get On Physical Therapy',
        from_name: e.target.full_name.value,
        email: e.target.email.value,
        phone: e.target.phone.value,
        gender: e.target.gender.value,
        appointmentDate: e.target.appointment_date.value,
        service: e.target.service.value,
        comments: e.target.comments.value,
      };

      const result = await emailjs.send(
        'service_get123',
        'template_email',
        templateParams,
        'TuBm6XhFF-8kg-kOr'
      );

      if (result.text === 'OK') {
        setStatus('Message sent successfully! We will contact you soon.');
        e.target.reset();
      } else {
        throw new Error('Failed to send message');
      }
    } catch (error) {
      console.error('EmailJS Error Details:', {
        message: error.message,
        text: error.text,
        name: error.name,
        stack: error.stack
      });
      setStatus('Failed to send message. Please try again.');
    }
  };

  return (
    <PageTransitionWrapper>
      <div className="container-fluid appointment py-5" id="contactUsSection">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.2">
              <div className="section-title text-start">
                <h4 className="sub-title pe-3 mb-0">Solutions To Your Pain</h4>
                <h1 className="display-4 mb-4">Best Quality Services With Minimal Pain Rate</h1>
                <p className="mb-4">Embrace a journey of profound relief and holistic recovery with our personalized pain management strategies. Our skilled professionals focus on the core sources of your pain, guiding you towards sustainable health and well-being, far beyond temporary solutions.</p>
                <div className="row g-4">
                  <div className="col-sm-6">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4">
                        <h5 className="mb-3">
                          <FontAwesomeIcon icon={faCheck} className="text-primary me-2" /> Body Relaxation
                        </h5>
                        <p className="mb-0">
                          Unlock complete tranquility with tailored relaxation methods, fostering serenity and balance in your life.
                        </p>
                      </div>
                      <div className="mb-4">
                        <h5 className="mb-3">
                          <FontAwesomeIcon icon={faCheck} className="text-primary me-2" /> Comprehensive Care
                        </h5>
                        <p className="mb-0">
                          Delve into holistic well-being with our all-encompassing care, blending modern and traditional healing.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="video h-100">
                      {/* Video or image placeholder */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
              <div className="appointment-form rounded p-5">
                <p className="fs-4 text-uppercase text-primary">Get In Touch</p>
                <h1 className="display-5 mb-4">Get Appointment</h1>
                <form onSubmit={sendEmail}>
                  <div className="row gy-3 gx-4">
                    <div className="col-xl-6">
                      <label htmlFor="fullName" className="form-label text-white">Full Name <span className="text-danger">*</span></label>
                      <input type="text" id="fullName" name="full_name" className="form-control py-3 border-primary bg-transparent text-white" placeholder="Full Name" required />
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="email" className="form-label text-white">Email <span className="text-danger">*</span></label>
                      <input type="email" id="email" name="email" className="form-control py-3 border-primary bg-transparent text-white" placeholder="Email" required />
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="phone" className="form-label text-white">Phone <span className="text-danger">*</span></label>
                      <input type="phone" id="phone" name="phone" className="form-control py-3 border-primary bg-transparent" placeholder="Phone" required />
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="gender" className="form-label text-white">Gender <span className="text-danger">*</span></label>
                      <select id="gender" name="gender" className="form-select py-3 border-primary bg-transparent" required>
                        <option value="" disabled selected>Your Gender</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="appointmentDate" className="form-label text-white">Appointment Date <span className="text-danger">*</span></label>
                      <input type="date" id="appointmentDate" name="appointment_date" className="form-control py-3 border-primary bg-transparent" required />
                    </div>
                    <div className="col-xl-6">
                      <label htmlFor="service" className="form-label text-white">Select Service <span className="text-danger">*</span></label>
                      <select id="service" name="service" className="form-select py-3 border-primary bg-transparent" required>
                        <option value="" disabled selected>Service</option>
                        <option value="Physical Therapy">Physical Therapy</option>
                        <option value="Massage Therapy">Massage Therapy</option>
                        <option value="Balance Therapy">Balance Therapy</option>
                        <option value="Post-Surgical Training">Post-Surgical Training</option>
                        <option value="Gait Training">Gait Training</option>
                        <option value="Work Injuries">Work Injuries</option>
                        <option value="Sports Injuries">Sports Injuries</option>
                        <option value="Ongoing Strength Training">Ongoing Strength Training</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <label htmlFor="comments" className="form-label text-white">Comments</label>
                      <textarea id="comments" name="comments" className="form-control border-primary bg-transparent text-white" cols={30} rows={5} placeholder="Write Comments"></textarea>
                    </div>
                    
                    {status && (
                      <div className={`alert ${status.includes('Failed') ? 'alert-danger' : 'alert-success'} mt-3`}>
                        {status}
                      </div>
                    )}
                    
                    <div className="col-12">
                      <button 
                        type="submit" 
                        className="btn btn-primary text-white w-100 py-3 px-5"
                        disabled={status === 'Sending...'}
                      >
                        {status === 'Sending...' ? 'SENDING...' : 'SUBMIT NOW'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default ContactUs;
