// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { init } from '@emailjs/browser';
import App from './App';

// Initialize EmailJS with your public key
init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
